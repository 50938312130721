import { useState, useCallback } from 'react'
import { useManageElasticSearchLazyQuery } from '../generated/graphql'
import { ApolloError } from '@apollo/client'

interface ElasticsearchProps {
  size?: number
  from?: number
  organizationId?: string
}

interface ElasticsearchResult {
  loading: boolean
  error: ApolloError | null
  data: {
    status?: string
    message?: string
    data?: any
  } | null
  search: (query: string) => Promise<{
    __typename?: 'ElasticSearchQueriesOutput'
    status?: string | null
    data?: any | null
    message?: string | null
  } | null>
}

export const useElasticsearch = ({
  size = 5,
  from = 0,
  organizationId = null,
}: ElasticsearchProps): ElasticsearchResult => {
  const [data, setData] = useState<ElasticsearchResult['data']>(null)
  const [executeSearch, { loading, error }] = useManageElasticSearchLazyQuery()

  const search = useCallback(
    async (query: string) => {
      try {
        const response = await executeSearch({
          variables: {
            query,
            size,
            from,
            organizationId,
          },
        })
        setData(response.data?.manage_elastic_search_queries || null)
        return response.data?.manage_elastic_search_queries
      } catch (err) {
        throw err instanceof Error ? err : new Error('Search failed')
      }
    },
    [executeSearch, size, from, organizationId],
  )

  return {
    loading,
    error,
    data,
    search,
  }
}
